import React, { useState } from "react";
import { PiMagnifyingGlassBold } from "react-icons/pi";
import './topbar.css'
import { Link } from "react-router-dom";
import { MdPersonOutline } from "react-icons/md";
import { MdOutlineShoppingCart } from "react-icons/md";
import { GiHamburgerMenu } from "react-icons/gi";
import { AnimatePresence, motion } from "framer-motion";

const navLinks = [
    { title: "What we do", href: "/" },
    { title: "How it works", href: "/" },
    { title: "About", href: "/" },
    { title: "Contact", href: "/" },
]


const menuVars = {
    initial: {
        scaleY: 0,
    },
    animate: {
        scaleY: 1,
        transition: {
            duration: 0.5,
            ease: [0.12, 0, 0.39, 0],
        }
    },
    exit: {
        scaleY: 0,
        transition: {
            delay: 0.5,
            duration: 0.5,
            ease: [0.22, 1, 0.36, 1],
        }
    },
}

const containerVars = {
    initial: {
        transition: {
            staggerChildren: 0.09,
            staggerDirection: -1,
        }
    },
    open: {
        transition: {
            delayChildren: 0.3,
            staggerChildren: 0.09,
            staggerDirection: 1
        }
    }
}

export default function TopBar() {
    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = () => {
        setIsOpen((open) => !open)
    }

    return (
        <div className='top'>
            <div className='topLeft'>
                <span className='topLeftLogo'><Link to="/" className="link">Joana & Dekas</Link></span>
            </div>
            <div className='topCenter'>
                <ul className='centerList'>
                    <li className='centerListItem'><Link to="/" className="link">Home</Link></li>
                    <li className='centerListItem'><Link to="/about" className="link">About</Link></li>
                </ul>
            </div>
            <div className='topRight'>
                <ul className='rightList'>
                    <li className='rightListItem icon'><PiMagnifyingGlassBold /></li>
                    <li className='rightListItem icon'><MdPersonOutline /></li>
                    <li className='rightListItem icon'><MdOutlineShoppingCart /></li>
                </ul>
                <div className="trigger" onClick={toggleMenu}><GiHamburgerMenu /></div>
            </div>
            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        variants={menuVars}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        className="menuContainer">
                        <div className="topElementsContainer">
                            <div className="topElements">
                                <h1 className="heading"></h1>
                                <p className="close" onClick={toggleMenu}>Close</p>
                            </div>
                            <motion.div
                                variants={containerVars}
                                initial="initial"
                                animate="open"
                                exit="initial"
                                className="menuLinks">
                                {navLinks.map((link, index) => {
                                    return (
                                        <div className="menuLink">
                                            <MobileNavLink
                                                key={index}
                                                title={link.title}
                                                href={link.href} />
                                        </div>
                                    );
                                })}
                            </motion.div>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    )
}

const mobileLinkVars = {
    initial: {
        y: "30vh",
        transition: {
            duration: 0.5,
            ease: [0.37, 0, 0.63, 1]
        },
    },
    open: {
        y: 0,
        transition: {
            ease: [0, 0.55, 0.45, 1],
            duration: 0.7,
        }
    }
}

const MobileNavLink = ({ title, href }) => {
    return (
        <motion.div
            variants={mobileLinkVars}
            className="mobileLinkWrapper">
            <Link to={href} className="mobileLink">{title}</Link>
        </motion.div>
    )
}