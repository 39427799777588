import About from "./about/About";
import Article from "./article/Article";
import Home from "./home/Home";
import TopBar from "./topbar/TopBar";
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";

function App() {
  return (
    <Router>
      <TopBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/:postId" element={<Article />} />
      </Routes>
    </Router>
  );
}

export default App;
