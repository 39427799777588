import './story.css'
import { Link } from "react-router-dom";

const Story = ({ id, date, tag, title, text, img, fullText }) => {
  return (
    <div className="container">
      <div className='stories'>
        <div className='story'>
          <Link to={`/${id}`} state={{ id, date, tag, title, text, img, fullText }} className="link">
            <img src={img} alt="" className='storyImg' />
          </Link>
          <div className='storyInfo'>
            <div className='storyMetadata'>
              <span className='storyDate'>{date}</span>
              <span className='spacer'>/</span>
              <span className='storyTag'>{tag}</span>
            </div>
            <span className='storyTitle'>
              <Link to={`/${id}`} state={{ id, date, tag, title, text, img, fullText }} className="link">{title}</Link>
            </span>
            <br />
            <br />
            <span className='storyText'>{text}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Story;