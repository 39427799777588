import Story from "../story/Story";

const storyDetails = {
    id: "therapeutic-power",
    date: "FEB 4, 2024",
    tag: "NEWS",
    title: "The Therapeutic Power of Blogging: A Journey to Personal Health",
    text: "In the contemporary digital landscape, where stress and anxiety often dominate, the practice of blog posting emerges as a therapeutic haven for personal well-being. Crafting a blog becomes a transformative experience, allowing individuals",
    img: "https://images.unsplash.com/photo-1515611926865-4fcb1c2ce28d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wxMTc3M3wwfDF8c2VhcmNofDZ8fHdlbGwlMjBiZWlnbnxlbnwwfHx8fDE3MDc3ODExNjB8MA&ixlib=rb-4.0.3&q=80&w=1920",
    fullText: `In the contemporary digital landscape, where stress and anxiety often dominate, the practice of blog posting emerges as a therapeutic haven for personal well-being. Crafting a blog becomes a transformative experience, allowing individuals to navigate the labyrinth of their thoughts and emotions. Here's a detailed exploration of twenty compelling reasons why engaging in regular blog posting can significantly benefit personal health. 

    The act of pouring one's thoughts onto the digital canvas offers a cathartic release, alleviating burdens that may weigh on the mind. Blogs provide a safe space to express emotions openly, fostering a deeper understanding of oneself and promoting emotional intelligence. Writing about personal experiences and reflections fosters self-discovery, allowing individuals to gain insights into their own thought processes. The process of writing serves as a stress-relief mechanism, helping to clear mental clutter and promoting a sense of calmness. 

    Sharing personal stories creates a sense of community, connecting individuals with like-minded people who may offer support and understanding. Blogging also provides a platform to focus on the positive aspects of life, cultivating a habit of gratitude that contributes to overall mental well-being. The act of blogging encourages mindfulness, as individuals immerse themselves in the present moment while articulating their thoughts. Engaging in creative writing stimulates the mind and promotes mental agility, contributing to overall cognitive health. 

    Setting and achieving blogging goals provides a sense of accomplishment, boosting self-esteem and confidence. Regularly documenting thoughts and experiences facilitates a heightened sense of self-awareness, leading to personal growth and development. Establishing a routine of blog posting becomes a therapeutic ritual, offering a consistent outlet for expression and reflection. Integrating positive affirmations into blog posts can reshape thought patterns, fostering a more optimistic mindset. 

    Blogging about personal health journeys inspires healthy lifestyle changes and encourages accountability. Sharing challenges and overcoming obstacles in a blog format promotes resilience and a positive outlook on life. Reading and responding to others' blogs cultivates empathy, creating a supportive online environment for mental health discussions. Blogging can serve as a constructive digital detox strategy, redirecting focus from overwhelming online content to personal expression. 

    The act of articulating thoughts in a blog format contributes to improved communication skills, both in writing and interpersonal relationships. Reflecting on the day's events and emotions through blogging can help individuals unwind, contributing to better sleep quality. Over time, a blog becomes a living testament to personal growth and transformation, serving as a reminder of the journey toward improved mental and emotional well-being.`
  }

  const storyDetails2 = {
    id: "mock-id",
    date: "MAR 5, 2024",
    tag: "FEATURED",
    title: "Exploring the Wonders of Mock Stories",
    text: "In this imaginary world of mock stories, we delve into the captivating tales of creativity and make-believe. Join us on a journey where the possibilities are endless.",
    img: "https://images.unsplash.com/photo-1600267147646-33cf514b5f3e?q=80&w=3494&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", // Placeholder image URL (you can replace it with an actual image URL)
    fullText: `In the contemporary digital landscape, where stress and anxiety often dominate, the practice of blog posting emerges as a therapeutic haven for personal well-being. Crafting a blog becomes a transformative experience, allowing individuals to navigate the labyrinth of their thoughts and emotions. Here's a detailed exploration of twenty compelling reasons why engaging in regular blog posting can significantly benefit personal health. 

    The act of pouring one's thoughts onto the digital canvas offers a cathartic release, alleviating burdens that may weigh on the mind. Blogs provide a safe space to express emotions openly, fostering a deeper understanding of oneself and promoting emotional intelligence. Writing about personal experiences and reflections fosters self-discovery, allowing individuals to gain insights into their own thought processes. The process of writing serves as a stress-relief mechanism, helping to clear mental clutter and promoting a sense of calmness. 

    Sharing personal stories creates a sense of community, connecting individuals with like-minded people who may offer support and understanding. Blogging also provides a platform to focus on the positive aspects of life, cultivating a habit of gratitude that contributes to overall mental well-being. The act of blogging encourages mindfulness, as individuals immerse themselves in the present moment while articulating their thoughts. Engaging in creative writing stimulates the mind and promotes mental agility, contributing to overall cognitive health. 

    Setting and achieving blogging goals provides a sense of accomplishment, boosting self-esteem and confidence. Regularly documenting thoughts and experiences facilitates a heightened sense of self-awareness, leading to personal growth and development. Establishing a routine of blog posting becomes a therapeutic ritual, offering a consistent outlet for expression and reflection. Integrating positive affirmations into blog posts can reshape thought patterns, fostering a more optimistic mindset. 

    Blogging about personal health journeys inspires healthy lifestyle changes and encourages accountability. Sharing challenges and overcoming obstacles in a blog format promotes resilience and a positive outlook on life. Reading and responding to others' blogs cultivates empathy, creating a supportive online environment for mental health discussions. Blogging can serve as a constructive digital detox strategy, redirecting focus from overwhelming online content to personal expression. 

    The act of articulating thoughts in a blog format contributes to improved communication skills, both in writing and interpersonal relationships. Reflecting on the day's events and emotions through blogging can help individuals unwind, contributing to better sleep quality. Over time, a blog becomes a living testament to personal growth and transformation, serving as a reminder of the journey toward improved mental and emotional well-being.`  
};

export default function Home() {
    return (
        <>
            <Story
                id={storyDetails.id}
                date={storyDetails.date}
                tag={storyDetails.tag}
                title={storyDetails.title}
                text={storyDetails.text}
                img={storyDetails.img}
                fullText={storyDetails.fullText}
            />
            <Story
                id={storyDetails2.id}
                date={storyDetails2.date}
                tag={storyDetails2.tag}
                title={storyDetails2.title}
                text={storyDetails2.text}
                img={storyDetails2.img}
            />
        </>
    )
}
