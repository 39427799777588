import { useLocation } from "react-router-dom";
import './article.css'

const Article = () => {
    const location = useLocation();
    const postDetails = location.state;
    console.log(postDetails)
    const {
        date,
        tag,
        title,
        img,
        fullText,
    } = postDetails;

    const paragraphs = fullText.split('\n').map((paragraph, index) => (
        <p key={index} className="paragraph">{paragraph}</p>
      ));

    return (
        <div className='main'>
            <div className='info'>
                <div className='metadata'>
                    <span className='storyDate'>{date}</span>
                    <span className='spacer'>/</span>
                    <span className='storyTag'>{tag}</span>
                </div>
                <div className="title">
                    <span>{title}</span>
                </div>
            </div>
            <div className='story'>
                <img src={img} alt="" className='img' />
            </div>
            <div className="fullText">
                <span>{paragraphs}</span>
            </div>
        </div>
    )
}

export default Article;